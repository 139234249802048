:root {
  --off-white: #efeff1;
}

body {
  font-family: 'Roboto', 'Arial', sans-serif;
  margin: 0;
}

.landing-page {
  min-height: 100vh;
  text-align: center;
  background: rgb(28, 94, 135);
  background: linear-gradient(to left bottom, rgb(28, 94, 135), #4e7eb3, #6196c0);

  /* background: lightseagreen; */
  /* background: linear-gradient(to left bottom, #ff9f4d, #ff7a36, #ff5e21, #ff3b3b); */
  color: var(--off-white);
}

.top-banner {
  padding: 64px 32px 0 32px;
}

@media (min-width: 1100px) {
  .landing-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .left-section,
  .right-section {
    width: 48%;
    display: inline-block;
    align-self: center;
  }

  .right-section {
    padding-top: 64px;
  }
}

.error-message {
  color: rgb(255, 165, 165);
  padding-top: 4px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.success-message {
  color: rgb(117, 255, 117);
  padding-top: 4px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.orkdm-logo {
  max-width: 150px;
}

.big-title {
  font-family: 'Lexend', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 4rem;
  margin: 10px 0;
}

.subtext {
  font-size: 1.5rem;
  margin-bottom: 64px;
  font-weight: 600;
}

.waitlist-section {
  padding: 0px 32px 32px 32px;
}

.waitlist-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.waitlist-section p {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.waitlist-form {
  display: flex;
  vertical-align: center;
  justify-content: center;
}

.or-string {
  margin-top: 8px;
}

.waitlist-form input {
  width: calc(100% - 120px);
  /* Adjust the width based on your design */
  max-width: 250px;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  /* margin-bottom: 10px; */
  font-size: 1.0rem;
  box-sizing: border-box;
}

.waitlist-form button {
  /* width: 120px; */
  /* Adjust the width based on your design */
  /* max-width: 150px; */
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  background-color: #333;
  color: var(--off-white);
  font-size: 1rem;
  cursor: pointer;
}

.waitlist-form button:hover {
  background-color: #555;
}

.privacy-policy {
  padding-top: 4px;
}

.go-section p {
  margin: 0;
  padding-bottom: 16px;
}

.go-section button {
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: var(--off-white);
  font-size: 1rem;
  cursor: pointer;
}

.go-section button:hover {
  background-color: #555;
}

.usp-section {
  text-align: center;
  padding: 0px 32px 64px 32px;
}

.usp-card {
  max-width: 300px;
  margin: 0 auto 16px;
  padding: 16px 16px 8px 16px;
  background-color: rgba(239, 239, 241, 0.84);
  /* background-color: var(--off-white); */
  color: #222;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.usp-card h3 {
  margin-top: 0;
  font-size: 1.35rem;
  min-height: 52px;
  text-align: center;
}

.usp-card p {
  color: #333;
}

.usp-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}